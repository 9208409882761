import React from 'react'
import location from "../image/location.svg";

export default function NoPage() {
    return (
        <>
            <main
            >
                <div className="mx-auto max-w-7xl px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">

                    <p className="mt-2 font-bold text-black-800 text-opacity-50 text-4xl">
                        Coming Soon
                    </p>
                    <br />
                    <p className="text-base font-semibold text-indigo-600">
                        We're working on bringing you this feature.
                    </p>
                    <div className="mt-6 flex justify-center items-center">
                        <img
                            className="mt-4 "
                            src={location}
                            alt=""
                            style={{maxWidth: '500px'}}
                        />
                        <a
                            href="/"
                            className="inline-flex items-center rounded-md border border-transparent bg-indigo-800
                             bg-opacity-75 mt-8  px-4 py-2 text-sm font-medium text-black-800 text-opacity-75
                             sm:bg-opacity-25 sm:hover:bg-opacity-50"
                        >
                            Go back home
                        </a>
                    </div>
                </div>
            </main>
        </>
    )
}