/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import {
    StarIcon,
    ChipIcon,
    CogIcon,
} from '@heroicons/react/outline'

import {CameraIcon, LightBulbIcon} from "@heroicons/react/solid";
import lightningBoltIcon from "@heroicons/react/solid/esm/LightningBoltIcon";
import Flow from "../flow/flowChart";



const features = [
    {
        name: 'Push to Deploy',
        description: 'We manage Marketplace and E-commerce storefronts i.e. Amazon.com, Google Shopping, and Walmart.com.',
        icon: ChipIcon,
    },
    {
        name: 'Website Crawler',
        description: 'Create or enrich your A+ content by retrieving the source code of product detail pages on your website.',
        icon: LightBulbIcon,
    },
    {
        name: 'Social Awareness',
        description: 'Using Google\'s API and social platforms analytics to improve conversion and product impressions.',
        icon: CameraIcon,
    },
    {
        name: 'Data Collection',
        description:  'Compare and review where your competitors are selling and converting online, and optimizing platforms.',
        icon: lightningBoltIcon,
    },
    {
        name: 'Powerful API',
        description: 'Our products API can provide competitor pricing and category insights to increase sales.',
        icon: CogIcon,
    },
    {
        name: 'Liquidity',
        description: 'We take ownership of inventory/products. Offering companies a holistic partnership and access to cash.',
        icon: StarIcon,
    },
]

export default function Feature() {
    return (
        <div className="relative bg-gray-800 py-24 sm:py-32 lg:py-40">

            <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
                <h2 className="text-lg font-semibold text-indigo-600">Deploy Faster</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">
                    Everything you need to deploy your products
                </p>
                <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                    Our dedicated team builds solutions to improve your products cross-platform visibility.
                </p>
                <div className="mt-20">
                    <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
                        {features.map((feature) => (
                            <div key={feature.name} className="pt-6">
                                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                                    <div className="-mt-6">
                                        <div>
                      <span className="inline-flex items-center justify-center rounded-xl bg-indigo-500 p-3 shadow-lg">
                        <feature.icon className="h-8 w-8 text-white" aria-hidden="true" />
                      </span>
                                        </div>
                                        <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                                            {feature.name}
                                        </h3>
                                        <p className="mt-5 text-base leading-7 text-gray-600">{feature.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Flow />
        </div>

    )
}
