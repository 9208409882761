import React, {Component} from 'react';

// import { ReCaptcha } from 'react-recaptcha-google';
import Reaptcha from 'reaptcha';

// import ReCAPTCHA from "react-google-recaptcha";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const notify = () => {
    const response = toast.promise(
        fetch("/notify", {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            }
        }),
        {
            pending: 'Email is pending',
            success: 'Email on it\'s way 👌',
            error: 'Email rejected 🤯'
        }
    );
    console.log(response)

}

    class Captcha extends Component {
        constructor(props) {
            super(props);
            this.state = {
                verified: false,
                notify: false
            };
        }

        onVerify = recaptchaResponse => {
            this.setState({
                verified: true,
            });
        };

        onNotify = notify => {
            this.setState({
                notify: true
            })
        }

        render() {
            return (
                <div>
                    <Reaptcha
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        onVerify={this.onVerify}
                        onNotify={this.onNotify}
                    />
                    <br>
                    </br>
                    <button
                        className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={notify} type="submit" disabled={!this.state.verified}>
                        Submit
                    </button>
                </div>
            );
        }
    }

export default Captcha;