
import React from 'react';
import { MailIcon } from '@heroicons/react/outline'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Captcha from "../components/captcha";


//Logic to submit message and receive email
    class Contact extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                name: '',
                to:'info@gproductsupply.com',
                subject: '',
                email: '',
                text: '',
                message: ''
            }

        }

        handleSubmit(e) {
            e.preventDefault();

            axios({
                mode: 'no-cors',
                method: "POST",
                url: "https://mail.gproductsupply.com:8080/server/", // Change to the url for development
                headers: {
                    'Content-Type': 'application/json',
                },
                data: this.state
            }).then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.message);
                    this.resetForm()
                } else if (response.data.status === 405) {
                    toast.error("Message failed to send.")
                }

            })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response);
                        console.log("server responded");
                    } else if (error.request) {
                        console.log("network error");
                    } else {
                        console.log(error);
                        toast.error(error.response)
                    }
                });


            //reload page after submit
            setTimeout(function () {
                // eslint-disable-next-line no-self-assign
                window.location = window.location;
            }, 5000);
        }

        resetForm() {
            this.setState({name: '', subject: '', email: '', message: ''})
        }

        render() {
            return (

                <div className="relative bg-white">
                    <div className="absolute inset-0">
                        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"/>
                    </div>
                    <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                            <div className="max-w-lg mx-auto">
                                <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl sm:tracking-tight">
                                    Get in touch
                                </h2>
                                <p className="mt-3 text-lg leading-6 text-gray-500">
                                    We are happy to get your feedback.
                                </p>
                                <dl className="mt-8 text-base text-gray-500">
                                    <div>
                                        <dt className="sr-only">Postal address</dt>
                                        <dd>
                                            <p>5201 Eden Avenue</p>
                                            <p>Suite 300</p>
                                            <p>Minneapolis, MN 55435</p>
                                        </dd>
                                    </div>
                                    {/*<div className="mt-6">*/}
                                    {/*    <dt className="sr-only">Phone number</dt>*/}
                                    {/*    <dd className="flex">*/}
                                    {/*        <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400"*/}
                                    {/*                   aria-hidden="true"/>*/}
                                    {/*        <span className="ml-3">+1 (555) 123-4567</span>*/}
                                    {/*    </dd>*/}
                                    {/*</div>*/}
                                    <div className="mt-3">
                                        <dt className="sr-only">Email</dt>
                                        <dd className="flex">
                                            <MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400"
                                                      aria-hidden="true"/>
                                            <span className="ml-3">info@gproductsupply.com</span>
                                        </dd>
                                    </div>
                                </dl>
                                <p className="mt-6 text-base text-gray-500">
                                    Looking for careers?{' '}
                                    <a href="src/pages/App#hero.js" className="font-medium text-gray-700 underline">
                                        View all job openings
                                    </a>
                                    .
                                </p>
                            </div>
                        </div>
                        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
                            <div className="max-w-lg mx-auto lg:max-w-none">
                                <div className="Contact">
                                    <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST"
                                          className="grid grid-cols-1 gap-y-6">
                                        <div className="form-group">

                                            <label htmlFor="name" className="sr-only">Name</label>

                                            <input required type="text" placeholder="Full name"
                                                   className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                                   id="name" value={this.state.name}
                                                   onChange={this.onNameChange.bind(this)}
                                            />
                                            {/*<label className="font-medium text-gray-700 underline">Required</label>*/}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="subject" className="sr-only">Subject</label>
                                            <input required type="text" placeholder="Subject"
                                                   className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                                   id="subject" value={this.state.subject}
                                                   onChange={this.onSubjectChange.bind(this)}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1" className="sr-only">Email</label>
                                            <input required type="email" placeholder="Email"
                                                   className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                   id="email" aria-describedby="emailHelp" value={this.state.email}
                                                   onChange={this.onEmailChange.bind(this)}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="message" className="sr-only">Message</label>
                                            <textarea required placeholder="Message"
                                                      className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                                      rows="4" id="message" value={this.state.message}
                                                      onChange={this.onMessageChange.bind(this)}/>

                                        </div>
                                        <Captcha />

                                        {/*<div>*/}
                                        {/*    <button onClick={notify} type="submit"*/}
                                        {/*            className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Submit*/}
                                        {/*    </button>*/}
                                        {/*</div>*/}

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            );
        }

        onNameChange(event) {
            this.setState({name: event.target.value})
        }

        onSubjectChange(event) {
            this.setState({subject: event.target.value})
        }

        onEmailChange(event) {
            this.setState({email: event.target.value})
        }

        onMessageChange(event) {
            this.setState({message: event.target.value})
        }

    }



export default Contact;


