import {Popover, Transition} from "@headlessui/react";
import {MenuIcon, XIcon} from "@heroicons/react/outline";
import React, {Fragment} from "react";
import world from '../image/world.svg'


const navigation = [
    { name: 'Solutions', href: '/feature' },
    { name: 'Company', href: '/company' },
    { name: 'Contact Us', href: '/contact' },
    { name: 'About', href: '/about' },
]

export default function Navbar1() {

    return (
        <div className= " z-[100] overflow-visible sticky top-0 bg-gray-800 pt-5 py-5">
        {/*<header className=" bg-gray-800 pt-10 py-5 ">*/}

            <Popover >

                <nav
                    className="top-0 max-w-7xl mx-auto flex items-center justify-between px-7 sm:px-6 lg:px-8"
                    aria-label="Global"
                >
                    <div className="flex items-center flex-1">
                        <div className="flex items-center justify-between w-full md:w-auto">
                            <a href="/">
                                <span className="sr-only">Workflow</span>
                                <img
                                    className="h-8 w-auto sm:h-10"
                                    src= { world }
                                    alt=""
                                />
                            </a>
                            <div className="-mr-2 flex items-center md:hidden">
                                <Popover.Button className="transition ease-in-out delay-150 bg-indigo-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300">
                                    <span className="sr-only">Open main menu</span>
                                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                </Popover.Button>
                            </div>
                        </div>
                        <div className="hidden space-x-10 md:flex md:ml-10">
                            {navigation.map((item) => (
                                <a key={item.name} href={item.href} className="font-medium text-white hover:text-gray-300">
                                    {item.name}
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className="hidden md:flex">
                        {/*<a*/}
                        {/*    href="#"*/}
                        {/*    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"*/}
                        {/*>*/}
                        {/*    Log in*/}
                        {/*</a>*/}
                    </div>
                </nav>

                <Transition
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right "
                    >
                        <div className="overflow-hidden rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 ">
                            <div className="px-5 pt-4 flex items-center justify-between">
                                <div>
                                    <img
                                        className="h-8 w-auto"
                                        src= { world }
                                        alt=""
                                    />
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Close menu</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="px-5 pt-5 pb-3 space-y-1">
                                {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="block w-full px-3 py-100 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            {/*<a*/}
                            {/*    href="#"*/}
                            {/*    className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100"*/}
                            {/*>*/}
                            {/*    Log in*/}
                            {/*</a>*/}
                        </div>
                    </Popover.Panel>
                </Transition>
            </Popover>

        {/*</header>*/}
        </div>

    )
}