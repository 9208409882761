import React from 'react';
import hero from '../image/hero.svg'
import  '../index.css'
import {ChevronRightIcon, DesktopComputerIcon} from '@heroicons/react/solid'
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Competitive Supply Chain Pricing',
    description:
        'Strategic product and inventory placement to ship directly to consumers or retail accounts.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Customer Service',
    description:
        'Our dedicated staff will assist you through out the supply chain process.',
    icon: DesktopComputerIcon,
  },
  {
    name: 'SEO and Listing Optimization',
    description:
        'Keep your retail partners and marketplaces stocked with your most up-to-date, accurate product information.',
    icon: LightningBoltIcon,
  },
  {
    name: 'Content Creation',
    description:
        'Save time and win over your competition with our A+ catalog template tailored to Amazon\'s specifications.',
    icon: AnnotationIcon,
  },
]


export default function Hero() {
  return (

      <div className="relative bg-gray-800 overflow-hidden">
        <div className="hidden sm:block sm:absolute sm:inset-0" aria-hidden="true">
          <svg
              className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
              width={364}
              height={384}
              viewBox="0 0 364 384"
              fill="none"
          >
            <defs>
              <pattern
                  id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} fill="currentColor" />
              </pattern>
            </defs>
            <rect width={364} height={384} fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" />
          </svg>
        </div>
        <div className=" relative pt-6 pb-16 sm:pb-24">
          <main className="mt-10 sm:mt-20">
            <div className="mx-auto max-w-7xl">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                  <div>
                    <a
                        href="/*"
                        className="inline-flex items-center text-white bg-gray-900 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                    >
                    <span className="px-3 py-0.5 text-white text-sm font-semibold leading-5 bg-indigo-500 rounded-full">
                      We're hiring
                    </span>
                      <span className="ml-4 text-sm">Visit our careers page</span>
                      <ChevronRightIcon className="ml-2 w-5 h-5 text-gray-500" aria-hidden="true" />
                    </a>
                    <h1 className="mt-4 text-4xl font-bold tracking-tight text-white sm:text-5xl sm:tracking-tight md:text-6xl md:tracking-tight">
                      Global Product Supply
                    </h1>
                    <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      Brand and platform representation without hassle.
                    </p>
                    {/*<p className="mt-8 text-base text-white font-semibold sm:mt-10">Used by</p>*/}
                    <div className="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                      <div className="flex flex-wrap items-start justify-between">
                        {/*<div className="flex justify-center px-1">*/}
                        {/*  <img*/}
                        {/*      className="h-14 sm:h-15"*/}
                        {/*      src={bb}*/}
                        {/*      alt="Bull Boxer"*/}
                        {/*  />*/}
                        {/*</div>*/}
                        {/*<div className="flex justify-center px-1">*/}
                        {/*  <img*/}
                        {/*      className="h-9 sm:h-10"*/}
                        {/*      src={google}*/}
                        {/*      alt="google"*/}
                        {/*  />*/}
                        {/*</div>*/}
                        {/*<div className="flex justify-center px-1">*/}
                        {/*  <img*/}
                        {/*      className="h-9 sm:h-10"*/}
                        {/*      src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg"*/}
                        {/*      alt="StaticKit"*/}
                        {/*  />*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-5">
                  <div> <img src= {hero} alt=" hero image" />
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <div className="py-12 bg-gray-800">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="lg:text-center">
                  <h2 className="text-lg text-indigo-500 font-semibold">Partnerships</h2>
                  <p className="mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-200 sm:text-4xl sm:tracking-tight">
                    A better way to ship products
                  </p>
                  <p className="mt-4 max-w-2xl text-xl text-gray-200 lg:mx-auto">
                    We're a premier supply chain and purchasing partner for all of your retail needs. We service ecommerce and legacy retail accounts.
                  </p>
                </div>

                <div className="mt-10">
                  <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                    {features.map((feature) => (
                        <div key={feature.name} className="relative">
                          <dt>
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                              <feature.icon className="h-6 w-6" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-lg leading-6 font-medium text-gray-200">{feature.name}</p>
                          </dt>
                          <dd className="mt-2 ml-16 text-base text-gray-400">{feature.description}</dd>
                        </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

  )
}




