
const nodes = [
    {
        id: 'A',
        type: 'group',
        position: { x: 0, y: 0 },
        style: {
            width: 170,
            height: 140,
        },
    },
    {
        id: 'A-1',
        type: 'input',
        data: { label: 'Analytics' },
        position: { x: 10, y: 10 },
        parentNode: 'A',
        extent: 'parent',
    },
    {
        id: 'A-2',
        data: { label: 'Data Scrape' },
        position: { x: 10, y: 90 },
        parentNode: 'A',
        extent: 'parent',
    },
    {
        id: 'B',
        type: 'output',
        position: { x: -100, y: 200 },
        data: null,
        style: {
            width: 190,
            height: 170,
            backgroundColor: 'rgba(240,240,240,0.25)',
        },
    },
    {
        id: 'B-1',
        data: { label: 'Content Creation' },
        position: { x: 50, y: 10 },
        parentNode: 'B',
        extent: 'parent',
        draggable: false,
        style: {
            width: 80,
        },
    },
    {
        id: 'B-2',
        data: { label: 'SEO/Listing Optimizer' },
        position: { x: 10, y: 90 },
        parentNode: 'B',
        extent: 'parent',
        draggable: false,
        style: {
            width: 80,
        },
    },
    {
        id: 'B-3',
        data: { label: 'A+ Content' },
        position: { x: 100, y: 90 },
        parentNode: 'B',
        extent: 'parent',
        draggable: false,
        style: {
            width: 80,
        },
    },
    {
        id: 'C',
        type: 'output',
        position: { x: 100, y: 200 },
        data: null,
        style: {
            width: 190,
            height: 170,
            backgroundColor: 'rgba(240,240,240,0.25)',
        },
    },
    {
        id: 'C-1',
        data: { label: 'Purchase Inventory' },
        position: { x: 10, y: 10 },
        parentNode: 'C',
        extent: 'parent',
        draggable: false,
        style: {
            width: 80,
        },
    },
    {
        id: 'C-2',
        data: { label: 'Publish to API' },
        position: { x: 10, y: 80 },
        parentNode: 'C',
        extent: 'parent',
        draggable: false,
        style: {
            width: 80,
        },
    },
    {
        id: 'C-3',
        data: { label: 'Receive Product in Warehouse' },
        position: { x: 100, y: 50 },
        parentNode: 'C',
        extent: 'parent',
        draggable: false,
        style: {
            width: 80,
        },
    },
    {
        id: 'D-1',
        data: { label: 'Ready for Shipping' },
        position: { x: 350, y: 200 },
        parentNode: '',
        extent: 'parent',
    },
    {
        id: 'E',
        type: 'default',
        className: 'annotation',
        data: {
            label: (
                <>
                    GPS handles the <strong>Entire</strong> process from beginning to end.{' '}
                    <strong>Yay</strong>.  🥳
                </>
            ),
        },
        draggable: false,
        selectable: false,
        position: { x: 150, y: 400 },
    },

];

export default nodes;
