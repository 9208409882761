export default [
    { id: 'a1-a2', source: 'A-1', target: 'A-2' },
    { id: 'a2-b', source: 'A-2', target: 'B' },
    { id: 'b1-b2', source: 'B-1', target: 'B-2' },
    { id: 'b1-b3', source: 'B-1', target: 'B-3' },
    { id: 'a2-b1', source: 'A-2', target: 'C' },
    { id: 'c1-c2', source: 'C-1', target: 'C-2' },
    { id: 'c2-c3', source: 'C-2', target: 'C-3' },
    { id: 'c1-c3', source: 'C-3', target: 'D-1' }
];
