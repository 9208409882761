
import React from 'react';
import './App.css';
import Navbar1 from './pages/navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Hero from './pages/hero';
import Contact from './pages/contact';
import Feature from './pages/feature';
import Company from "./pages/company";
import About from "./pages/about"
import NoPage from "./pages/404";
import 'react-toastify/dist/ReactToastify.css';
import Privacy from "./pages/privacy";


function App() {
    return (
        <Router>
            <Navbar1 />
            <Routes>
                <Route exact path='/'  element={<Hero />} />
                <Route path='/feature' element={<Feature />} />
                <Route path='/company' element={<Company />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/about' element={<About />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/*' element={<NoPage />} />
                {/*<Route path='/blogs' component={Blogs} />*/}
                {/*<Route path='/sign-up' component={SignUp} />*/}
            </Routes>
        </Router>
    );
}

export default App;