import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Footer from "./pages/footer";
import App from "./App.js";
import * as PropTypes from "prop-types";
import {ToastContainer} from "react-toastify";




const root = ReactDOM.createRoot(document.getElementById('root'));

function Link(props) {
    return null;
}

Link.propTypes = {
    to: PropTypes.string,
    children: PropTypes.node
};
root.render(

    <React.StrictMode>
        <App />
        <ToastContainer />
        <Footer />
    </React.StrictMode>,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

