
import React from 'react';
import connected from '../image/connected.svg'

const stats = [
    { label: 'Founded', value: '2021' },
    { label: 'Employees', value: '5' },
    { label: 'Users', value: '5' },
    { label: 'Revenue', value: '$10M' },
]

const About = () => {
    return (
        <div className="relative bg-gray-800 py-16 sm:py-24">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:items-start lg:gap-24 lg:px-8">
                <div className="relative sm:py-16 lg:py-0">
                    <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                        <div className="absolute inset-y-0 right-1/2 w-full rounded-r-3xl bg-gray-50 lg:right-72"/>
                        <svg
                            className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                            width={404}
                            height={392}
                            fill="none"
                            viewBox="0 0 404 392"
                        >
                            <defs>
                                <pattern
                                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                                          fill="currentColor"/>
                                </pattern>
                            </defs>
                            <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"/>
                        </svg>
                    </div>
                    <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
                        {/* Testimonial card*/}
                        <div className="relative overflow-hidden rounded-2xl pt-64 pb-10 shadow-xl">
                            <img
                                className="absolute inset-0 h-full w-full object-cover"
                                src= { connected }
                                alt=""
                            />
                            <div className="absolute inset-0 bg-indigo-500 mix-blend-multiply"/>
                            <div
                                className="absolute inset-0 bg-gradient-to-t from-indigo-600 via-indigo-600 opacity-90"/>
                            <div className="relative px-8">
                                {/*<div>*/}
                                {/*    <img*/}
                                {/*        className="h-12"*/}
                                {/*        src= {connected}*/}
                                {/*        alt="Workcation"*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <blockquote className="mt-8">
                                    <div className="relative text-lg font-medium text-white md:flex-grow">
                                        <svg
                                            className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-indigo-400"
                                            fill="currentColor"
                                            viewBox="0 0 32 32"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"/>
                                        </svg>
                                        <p className="relative">

                                        </p>
                                    </div>
                                    <br></br>
                                    <footer className="mt-4">
                                        <p className="text-base font-semibold text-indigo-200">
                                            Our value is to provide liquidity and a sound logistics model.
                                            </p>
                                        <p className="text-base font-semibold text-indigo-200">
                                            Brian Landun, CEO at Global Product Supply
                                        </p>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                    {/* Content area */}
                    <div className="pt-12 sm:pt-16 lg:pt-20">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">
                            On a mission to empower teams
                        </h2>
                        <div className="mt-6 space-y-6 text-gray-300">
                            <p className="text-lg">
                                At Global Product Supply, our core mission is to bridge the gap between remarkable
                                brands and the vast online consumer base. We understand the complexities of online
                                retail and strive to simplify this process for brands around the globe.
                                By partnering with us, brands gain a steadfast ally in navigating the
                                digital marketplace landscape.
                            </p>
                            <p className="text-base leading-7">
                                Our journey began in 2021, with a vision to revolutionize online brand representation.
                                Recognizing the challenges brands face in maximizing their online presence,
                                we set out to offer comprehensive solutions that encompass content creation,
                                advertising spend management, logistics, and cash flow handling for all purchases.

                                Our team of experts specializes in creating compelling content that resonates with your
                                target audience, ensuring your brand's story is told in a way that not only engages
                                but also converts. With our strategic advertising approaches, we maximize your visibility
                                and impact across platforms, ensuring your products stand out in a crowded marketplace.

                                Logistics and cash flow management are pivotal to online retail success,
                                and at Global Product Supply, we handle these aspects meticulously. Our aim is to
                                streamline your operations, making sure that your products reach your customers
                                efficiently, while optimizing your investments and returns.
                            </p>
                            <p className="text-base leading-7">
                                Global Product Supply is more than just a service provider; we are your partner in growth.
                                As we continue to evolve and expand our services, our commitment to our partners
                                remains unwavering. We look forward to forging new partnerships and continuing to
                                deliver excellence in the
                                ever-changing landscape of online marketplaces.

                                Join us in our journey to redefine online retail and take your brand to new heights.
                                Together, let's achieve unparalleled success in the digital marketplace

                            </p>
                        </div>
                    </div>

                    {/* Stats section */}
                    <div className="mt-10">
                        <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                            {stats.map((stat) => (
                                <div key={stat.label} className="border-t-2 border-gray-100 pt-6">
                                    <dt className="text-base font-medium text-gray-200">{stat.label}</dt>
                                    <dd className="text-3xl font-bold tracking-tight text-gray-200">{stat.value}</dd>
                                </div>
                            ))}
                        </dl>
                        <div className="mt-10">
                            <a href= '/contact' className="text-base font-medium text-indigo-600">
                                contact us to learn more
                                <span aria-hidden="true"> &rarr;</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;