
import { useCallback, useState } from 'react';
import ReactFlow, { addEdge, applyEdgeChanges, applyNodeChanges, Background, MiniMap, Controls } from 'reactflow';
import 'reactflow/dist/style.css';

import initialNodes from './nodes.js';
import initialEdges from './edges.js';

const rfStyle = {
    backgroundColor: '#49337e',
    height: 500,

};

const edgeOptions = {
    animated: true,
    style: {
        stroke: 'white',
    },
};

const connectionLineStyle = { stroke: 'white' };

function Flow() {
    const [nodes, setNodes] = useState(initialNodes);
    const [edges, setEdges] = useState(initialEdges);

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );
    const onConnect = useCallback(
        (connection) => setEdges((eds) => addEdge(connection, eds)),
        [setEdges]
    );

    return (
        <div style={rfStyle}>
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            defaultEdgeOptions={edgeOptions}
            fitView
            style={rfStyle}
            attributionPosition="top-right"
            connectionLineStyle={connectionLineStyle}

        >
            <MiniMap />
            <Controls />
            <Background />
        </ReactFlow>
        </div>
    );
}

export default Flow;